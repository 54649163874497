import { Component, Emit, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import DispatchMixin from "@/mixins/Dispatch";
import { SelectedPrivatePoisEntity } from "@/models/persons/v12/PrivatePois";
import Uploader from "@/views/Admin/PrivatePois/Dependencies/Uploader/index.vue";
import DeleteDialog from "@/components/Content/Dialogs/Delete/index.vue";
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import ViewIconLatLon from "@/components/Commons/Maps/ViewIconLatLon/index.vue";
import CardButton from "@/components/Content/CardButton.vue";
import { TypeLoading } from "@/interfaces/loading";
import { DialogDeleteEntity } from "@/models/Core/Dialogs/Delete";
import { DeleteType, DialogDeleteType } from "@/interfaces/Core/dialog";

@Component({
	components: { Uploader, IconBase, CardButton, DeleteDialog, ViewIconLatLon },
	mixins: [DispatchMixin],
})
export default class PrivatePoisItems extends Vue {
	/**
	 * DATA
	 */
	public headers: Record<string, any>[] = [
		{
			text: "privatepois.fields.items.name",
			align: "start",
			value: "name",
			sortable: false,
		},
		{ text: "privatepois.fields.items.lat", value: "lat", sortable: false },
		{ text: "privatepois.fields.items.lon", value: "lon", sortable: false },
		{ text: "privatepois.fields.items.h3", value: "h3", sortable: false },
		{ text: "privatepois.fields.items.res", value: "res", sortable: false },
		{ text: "privatepois.fields.items.polygon", value: "polygon", sortable: false },
		{ text: "", value: "actions", sortable: false,  width: '150' },
	];

	/**
	 * Los elementos seleccionados en el checkbox de la tabla
	 */
	public selectedItems: Record<string, any>[] = [];

	/**
	 * Datos que controla el dialog del componente DeleteDialog
	 */
	public dialogDeleteData: DialogDeleteEntity = new DialogDeleteEntity();

	/**
	 * GETTERS
	 */
	@Getter("privatePois/getSelectedPrivatePois")
	selectedPrivatePois!: SelectedPrivatePoisEntity;

	public get getPaginatedItems() {
		return this.selectedPrivatePois.items;
	}

	public get getItems() {
		return this.selectedPrivatePois.items.data.map((d) => {
			return {
				name: d.nombre,
				lat: d.lat,
				lon: d.lon,
				h3: d.h3_res,
				res: d.res,
				polygon: d.polygon
			};
		});
	}

	public get currentPage() {
		const { current_page } = this.selectedPrivatePois.items;
		return current_page;
	}

	public set currentPage(val: number) {
		this.onSetCurrentPage(val);
	}

	public get getLength() {
		const { total, per_page } = this.selectedPrivatePois.items;
		if (!total || !per_page) return 0;
		return Math.ceil(total / per_page);
	}

	public get perPage() {
		const { per_page } = this.selectedPrivatePois.items;
		if (!per_page) return 25;
		return Number(per_page);
	}

	public get getTotal() {
		const { total } = this.selectedPrivatePois.items;
		return total;
	}

	public get isDialogOpen() {
		return this.dialogDeleteData.open;
	}

	public get getDialogDeleteType() {
		return DialogDeleteType;
	}

	public get btnDeleteText() {
		const count: number = this.selectedItems.length;

		if (!count) return this.$t("privatepois.btn.delete");

		return this.$t("privatepois.btn.delete_items", {
			count: this.selectedItems.length,
		});
	}

	/**
	 * METHOD
	 */
	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;

	private async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	/**
	 * Llamada a la API
	 * Delete Item
	 * @param items
	 */
	private async fetchDeleteItems(items: string[]) {
		try {
			//this.setLoadingData(TypeLoading.loading);
			this.dialogDeleteData.setLoading(true);

			const params = {
				id: this.selectedPrivatePois.private_pois.id,
				payload: { poi_names: items },
			};

			await this.dispatchStore("privatePois/deleteData", params);

			this.dialogDeleteData.setLoading(false);

			await this.onSetCurrentPage(1);

			//this.setLoadingData();
		} catch (error) {
			//this.setLoadingData();
			this.dialogDeleteData.setLoading(false);
		}
	}

	/**
	 * Funcion que setea los datos del componente DeleteDialog
	 * @param type
	 * @param data
	 */
	public setDialogDeleteData(type: DialogDeleteType, data?: any) {
		const title: string = `delete.privatePois.${type}.title`;
		const message: string = `delete.privatePois.${type}.message`;

		this.dialogDeleteData.setTitle(title);
		this.dialogDeleteData.setType(type);
		this.dialogDeleteData.setMessage(message);
		this.dialogDeleteData.setData(data);
		this.dialogDeleteData.setOpen(true);
	}

	/**
	 * Handler del boton eliminar los seleccionados
	 * @param type
	 * @param item
	 */
	public handleRemoveSelected(type: DialogDeleteType, item?: any) {
		this.setDialogDeleteData(type, item);
	}

	/**
	 * Funcion que prepara el payload
	 * Envia solicitud a la API para eliminar item
	 * Type: SINGLE
	 * @param item
	 */
	public async deleteItem(item: any) {
		const payload: string[] = [item.name];
		await this.fetchDeleteItems(payload);
	}

	/**
	 * Funcion que prepara el payload
	 * Envia solicitud a la API para eliminar items seleccionados
	 * Type: ALL
	 */
	public async deleteSelectedItems() {
		const payload: string[] = this.selectedItems.map((s) => s.name);
		await this.fetchDeleteItems(payload);
	}

	/**
	 * HANDLER
	 * Accion de botons de DeleteDialog
	 * @param deleteParams
	 */
	public async handleDeleteAction(deleteParams: {
		event: DeleteType;
		type: DialogDeleteType;
		data: any | any[];
	}) {
		const { event, type, data } = deleteParams;

		if (event === DeleteType.CANCEL) {
			this.resetData();
		}

		if (event === DeleteType.CONFIRM) {
			await this.processDeleteAction(event, type, data);
		}
	}

	private async processDeleteAction(
		event: DeleteType,
		type: DialogDeleteType,
		data: any | any[]
	) {
		this.dialogDeleteData.setLoading(true);

		if (type === DialogDeleteType.SINGLE) {
			await this.deleteItem(data);
		}

		if (type === DialogDeleteType.ALL) {
			await this.deleteSelectedItems();
		}

		this.resetData();
	}

	private resetData() {
		this.selectedItems = [];
		this.dialogDeleteData = new DialogDeleteEntity();
	}

	/**
	 * EMITTERS
	 */

	@Emit("update")
	public async onSetCurrentPage(val: number) {
		return { page: val };
	}
}
